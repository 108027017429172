@media screen and (min-width: 992px) and (max-width:1199px) {
    .login-pd-main {
        padding: 50px 30px;
    }

    .login-form-details .login-max-content,
    .signup-form-details .signup-max-details {
        max-width: initial;
        margin: initial;
    }

    .container {
        max-width: 960px;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 55px;
        line-height: 61px;
        margin: auto auto 57px auto;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 19px;
        line-height: 30px;
        max-width: inherit;
    }

    .joyful-bg-top {
        top: -8px;
    }

    .joyful-race-content {
        max-width: initial;
        height: 547px;
        width: 100%;
    }

    .jyoful-main-content .pd-joyful-investment {
        padding: 70px 0px 70px 0px;
    }

    .roadmap-pd-list {
        padding: 70px 0px 304px 0px;
    }

    .roadmap-title-content .title-roadmap-details,
    .referral-main-title-box .small-referral-title,
    .contact-us-details .contactus-title {
        font-size: 50px;
        line-height: 60px;
    }

    .roadmap-title-content .title-roadmap-details {
        margin-bottom: 91.7px;
    }

    .roadmap-title-content .shadow-contnet-title-comman,
    .referral-main-title-box .shadow-referral-title,
    .contact-us-details .contactus-title-shadow {
        font-size: 67px;
        line-height: 76px;
    }

    .referral-friend-content {
        padding-top: 13px;
    }

    .referral-friend-content::after {
        width: 381px;
        height: 733px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .refrral-friend-bg .refrral-mobile-img {
        height: 548px;
    }

    .footer-bg-rounded {
        height: initial;
    }

    .svg-rounded-border .footer-line-bg {
        max-width: 487px;
        height: 487px;
    }

    .footer-logo {
        height: 100px;
        max-width: 150px;
        margin: auto auto 20px auto;
    }

    .address-footer-main {
        margin-bottom: 23px;
    }

    .footer-details-main .footer-title {
        line-height: 49px;
        font-size: 25px;
        margin-bottom: 10px;
    }

    /* .token-presale-background {
        height: 100vh !important;
    } */
    .billionair-tokenpre-main {
        margin-top: 60px;
    }

    .token-presale-background {
        height: 100%;
        min-height: calc(100vh - 47px);
    }

    /* .white-paper-margin {
        margin-top: 173px;
    } */
    .user-sidebar-logo {
        height: 52px !important;
    }

    .res-primary-btn {
        display: flex;
        margin: 25px 0px 0px;
    }

    .res-dash-referral-btn-main {
        display: block;
    }

    .white-paper-margin {
        margin-top: 189px;
    }

    .icon-setclose {
        display: block;

        margin: 25px 0px 0px;
    }

    .res-dash-referral-btn-main {
        display: block;
    }

    .white-paper-margin {
        margin-top: 189px;
    }

    .icon-setclose {
        display: block;
    }


}

@media screen and (max-width:991px) {

    /* / 22-6 start /  */
    .referral-list-tree-btn {
        display: flex !important;
        justify-content: space-between;
        flex-direction: column;
    }

    .res-referral-list-tree-btn {
        margin-bottom: 20px !important;
    }

    /* / 22-6 end / */
    .user-sidebar-link.active {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mobile-show.overlay-mobile-active {
        display: block !important;
    }

    .center-list-header .header-ul-list.header-left-active {
        left: 0px;
    }

    .header-override {
        overflow: hidden;
    }

    .overlay-section {
        background: hsla(0, 3%, 8%, .5);
        bottom: 0;
        max-width: 100% !important;
        position: fixed !important;
        right: 0;
        top: 0 !important;
        transition: all .3s !important;
        width: 100% !important;
        z-index: 99;
    }

    .burgur-icon-main {
        display: block;
    }

    .center-list-header .header-ul-list {
        padding: 10px 0px;
        position: fixed;
        left: -253px;
        transition: 0.5s all;
        flex-direction: column;
        top: 0;
        background: #032058;
        min-width: 250px;
        z-index: 999;
        height: 100vh;
        bottom: 0;
        overflow: auto;
    }

    .center-list-header .header-ul-list .header-listitem-li {
        padding: 10px 20px;
        width: 100%;
        text-align: start;
        max-width: 100%;
    }

}

@media screen and (min-width: 768px) and (max-width: 991px) {

    /* .login-bg-content,
    .signup-bg-content {
        height: 30vh;
        background-image: none;
        background-color: #032058;
    } */

    .signup-form-details .signup-max-details {
        max-width: initial;
        margin: initial;
        width: 100%;
    }

    .signup-form-details .signin-btn-main .comman-primary-btn {
        min-width: 49%;
    }

    .login-form-details .login-max-content {
        min-width: 400px;
    }

    .login-pd-main {
        padding: 50px;
        /* height: calc(100vh - 30vh); */
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .container {
        max-width: 720px;
    }

    .login-logo-details .logo-login {
        height: 120px;
    }

    .logo-main-left,
    .logo-header {
        height: 60px;
    }

    .primary-btn-main-user .comman-primary-btn,
    .outline-btn-main .outline-btn-list {
        border-radius: 10px;
        font-size: 14px;
        line-height: 20px;
        min-height: 40px;
    }

    .home-text-content-top .home-pd-contnet {
        min-height: 500px
    }

    .home-text-content-top,
    .home-inner-main {
        min-height: 600px;
    }

    .home-content-details .home-start-your-fitness {
        font-size: 50px;
        line-height: 59px;
        min-height: initial;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 40px;
        line-height: 46px;
        max-width: 492px;
        margin: auto auto 57px auto;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 14px;
        line-height: 22px;
    }

    .virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem {
        margin-bottom: 15.5px;
    }

    .joyful-bg-top {
        top: -40px;
    }

    .joyful-race-content {
        max-width: 267px;
        height: 397px;
        margin-left: -15px;
    }

    .jyoful-main-content .pd-joyful-investment {
        padding: 70px 0px 70px 0px;
    }

    .roadmap-pd-list {
        padding: 70px 0px 297px 0px;
    }

    .refrrel-icon {
        width: 95px;
        height: 95px;
    }

    .flex-referral {
        margin-bottom: 10px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 18px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 16px;
        line-height: 23px;
    }

    .referral-content-details .referral-details-card {
        font-size: 14px;
        line-height: 24px;
    }

    .referral-friend-content {
        padding-top: 35px;
    }

    .refrral-friend-bg .refrral-mobile-img {
        max-width: 330.11px;
        height: 502px;
    }

    .refrral-friend-bg {
        margin: auto 22px auto 40px;
        max-width: 321.11px;
    }

    .referral-friend-content::after {
        width: 311px;
        height: 693px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .referral-details-main {
        padding: 70px 0px 70px 0px;
    }

    .referral-main-title-box .small-referral-title {
        font-size: 40px;
        line-height: 45px;
        margin-top: -15px;
    }

    .roadmap-title-content .title-roadmap-details,
    .contact-us-details .contactus-title {
        font-size: 40px;
        line-height: 45px;
        margin-top: -15px;
    }

    .referral-main-title-box .shadow-referral-title,
    .roadmap-title-content .shadow-contnet-title-comman,
    .contact-us-details .contactus-title-shadow {
        font-size: 50px;
        line-height: 55px;
        letter-spacing: 4px;
    }

    .roadmap-title-content .title-roadmap-details {
        margin-bottom: 52.7px;
    }

    .svg-rounded-border .footer-line-bg {
        display: none;
    }

    .rounde-footer-inner-details {
        max-width: initial;
        margin: 0px;
    }

    .footer-details-main .footer-title {
        line-height: 26px;
        font-size: 24px;
    }

    .footer-details-main .footer-desc {
        font-size: 11px;
        line-height: 19px;
    }

    .footer-details-main .footer-desc {
        font-size: 11px;
        line-height: 19px;
        margin-bottom: 15px;
    }

    .footer-form-details .footer-grid .footer-column {
        margin-bottom: 15px;
    }

    .address-footer-main {
        margin-bottom: 20px;
    }

    .footer-pos-main {
        padding-top: 20px;
    }

    .admin-login-main-flex {
        flex-direction: column;
        height: auto;
    }

    .admin-login-left-main,
    .admin-login-right-main {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .admin-login-box {
        padding: 50px 15px 100px 15px;
    }

    .login-left-bg {
        height: 50vh;
        background-size: cover;
    }

    .user-list-flex {
        align-items: flex-start;
        margin-bottom: 24px;
        flex-direction: column;
    }

    /* .token-presale-background {
    height: 100vh;
  } */
    .login-bg-content {
        display: none;
    }

    .bottom-bg-footer .copyright-title {
        font-size: 12px;
    }

    .footer-ul li a {
        padding: 0px 10px;
        font-size: 12px;

    }

    .billionair-tokenpre-main {
        margin-top: 60px;
    }

    .token-presale-background {
        height: 100%;
        min-height: calc(100vh - 47px);
    }

    /* 6-6 start */


    /* .dashboard-left-main {
        flex: unset;
        max-width: unset;
        width: unset;
    } */

    .admin-sidebar-main {
        height: 100%;
        /* left: -300px; */
    }



    .admin-sidebar-logo {
        padding: 8px 0;
        height: 60px;
    }

    .res-primary-btn {
        display: flex;
        margin: 25px 0px 0px;
    }

    .res-dash-referral-btn-main {
        display: block;
    }

    /* 6-6 END */
    .billionair-tokenpre-main {
        margin-top: 60px;
    }

    .token-presale-background {
        height: 100%;
        min-height: calc(100vh - 47px);
    }

    .user-sidebar-logo {
        height: 52px !important;
    }

    .set-modal-line:after {
        right: -19px;
    }

    .admin-dashboard-inner-box .user-dash-price {

        font-size: 20px;
        line-height: 26px;

    }

    .user-sidebar-logo-main {
        text-align: left !important;
        height: 59px;
        background: #27170a !important;
    }

    .icon-setclose {
        display: none !important;
    }



    /* 8-6- start */
    .res-join-wait-input input {
        min-width: 200px !important;
    }

    .dashboard-main.active .admin-header-main {
        left: 0;
    }

    /* 8-6 end */

    /* 12-6 start */

    .refferaltables-main {
        width: 100%;
    }

    .user-header-main {
        height: 60px;
    }

    .admin-dashboard-inner-box .user-dash-text {
        white-space: normal;
        font-size: 10px;
    }

    /* 12- 6-end */
    .ref-card-main {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }

    .ref-card-inner {
        margin: 9px 6px;
    }

    .flex-table {
        display: block;
    }

    .page-table-main .table tr th,
    .page-table-main .table tr td {
        white-space: nowrap;
    }

    .otp-flex-main2 {
        max-width: 100%;
    }

    .admin-tabs-main .MuiTabs-scroller {
        overflow: auto !important;
    }

}



@media screen and (max-width:767px) {
    .res-stack-box{
        margin-left: 5px !important;
    }
    .res-primary-stack {
        display: flex;
    }
    .main-stack-box{
        display: none;
    }
    .marquee-text {
        max-width: 100%;
        width: 100%;
    }

    .otp-flex-main2 {
        max-width: 100%;
    }

    .login-pd-main {
        padding: 50px 15px;
        /* height: calc(100vh - 20vh); */
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    /* .login-bg-content,
    .signup-bg-content {
        height: 20vh;
        background-image: none;
        background-color: #032058;
    } */

    .flex-check-login {
        margin-top: 10px;
    }

    .login-details-title .login-title {
        font-size: 50px;
        line-height: 60px;
    }

    .mb-20px {
        margin-bottom: 10px;
    }

    .login-form-details .login-max-content {
        max-width: initial;
        margin: initial;
        width: 100%;
    }

    .login-logo-details .logo-login {
        height: 100px;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 32px;
        line-height: 40px;
        margin: auto auto 35px auto;
    }

    .max-content-roadmap .list-roadmap-details::after,
    .bottom-arrow-roadmap .roadmap-arrow-bottom,
    .roadmapbg-main-contant::after,
    .referral-main-title-box .shadow-referral-title,
    .roadmap-title-content .shadow-contnet-title-comman,
    .svg-rounded-border .footer-line-bg,
    .contact-us-details .contactus-title-shadow {
        display: none;
    }

    .rounde-footer-inner-details {
        max-width: initial;
        margin: initial;
    }

    .list-roadmap-details .listitem-roadmap-details {
        padding: 0px 0px 35.1px 0px;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .road-content-max-list .rounded-road-year-flex {
        flex-direction: column-reverse;
        align-items: end;
    }

    .road-content-max-list .rounded-road-year-flex {
        align-items: start;
        flex-direction: column-reverse;
    }

    .rounded-road-year-flex .year-text-contnet {
        margin-right: 0px;
    }

    .bg-year-rounded img {
        width: 100%;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .roadmap-details-content {
        margin: -18px 0px 0px 60px;
    }

    .roadmap-details-content {
        max-width: inherit;
        margin: -18px 48px 0px 0px;
    }

    .roadmap-pd-list {
        padding: 50px 0px 50px 0px;
    }

    .referral-details-main {
        padding: 50px 0px 80px 0px;
    }

    .rounded-road-year-flex .qtitle-roadmap {
        margin-bottom: -17px;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .rounded-road-year-flex .year-text-contnet {
        margin-left: 0px;
    }

    .roadmap-title-content .title-roadmap-details,
    .referral-main-title-box .small-referral-title,
    .contact-us-details .contactus-title {
        font-size: 40px;
        margin-bottom: 20.7px;
        line-height: 50px;
        margin-top: -16px;
    }

    .title-referral-main .description-referral-details {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 23px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 14px;
        line-height: 18px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 18px;
        line-height: 24px;
    }

    .referral-content-details .referral-details-card {
        font-size: 15px;
        line-height: 22px;
    }

    .flex-referral {
        margin-bottom: 15px;
    }

    img.refrrel-icon {
        width: 100px;
        height: 100px;
    }

    .referral-friend-content {
        padding-top: 25px;
    }

    .joyful-race-content {
        width: 100%;
        height: 497px;
    }

    .jyoful-main-content .pd-joyful-investment {
        padding: 50px 0px 50px 0px;
    }

    .virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem {
        margin-bottom: 15px;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 18px;
        line-height: 28px;
        max-width: initial;
    }

    .pos-relative-joyful {
        display: flex;
        flex-direction: column-reverse;
    }

    .joyful-bg-top {
        position: initial;
    }

    .logo-main-left,
    .logo-header {
        height: 55px;
    }

    .primary-btn-main-user .comman-primary-btn {
        border-radius: 10px;
        min-height: 38px;
        font-size: 14px;
    }

    .home-content-details .home-start-your-fitness {
        font-size: 43px;
        min-height: initial;
        line-height: 46px;
    }

    .home-text-content-top .home-pd-contnet {
        min-height: 485px;
    }

    .home-text-content-top {
        min-height: 600px;
        padding-top: 75px;
    }

    .home-inner-main {
        min-height: 600px;
    }

    .hero-pos-relative {
        margin-top: -75px;
    }

    .referral-friend-content::after {
        bottom: -39px;
        right: -44%;
        width: 437px;
        height: 480px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: initial;
    }

    .refrral-friend-bg .refrral-mobile-img {
        max-width: 330.11px;
        height: 388px;
        margin-top: 52px;
    }

    .refrral-friend-bg {
        max-width: 300.11px;
        margin-left: auto;
        margin-right: initial;
    }

    .footer-logo {
        height: 100px;
        max-width: initial;
        text-align: center;
        margin: 0px 0px 15px 0px;
    }

    .address-footer-main .footer-add-ul .footer-add-li {
        justify-content: center;
        padding: 0px 0px 4px 0px;
    }

    .footer-bg-rounded,
    .footer-social-icon-main .footer-social-icon-ul {
        align-items: start;
        justify-content: start;
    }

    .footer-bg-rounded {
        height: initial;
        margin-bottom: 40px;
        justify-content: center;
    }

    .address-footer-main {
        margin-bottom: 15px;
    }

    .footer-details-main .footer-desc {
        font-size: 11px;
        line-height: 19px;
        margin-bottom: 26px;
        text-align: center;
    }

    .footer-details-main .footer-title {
        line-height: 35px;
        font-size: 25px;
        margin-bottom: 20px;
        text-align: center;

    }

    /* 31/5/2023 */
    /* .token-presale-background {
    height: 100%;
} */
    .billionair-list-content .hero-token-presale {
        font-size: 21px;
        line-height: 35.4px;
        margin-bottom: 9.41px;
    }

    .billionair-list-content .hero-token-details-presale {
        font-size: 12px;
        line-height: 21.12px;
        margin-bottom: 9.87px;
    }

    .main-time-box .time-numer-presale {
        color: #fff;
        font-size: 29px;
        line-height: 43.6px;
        margin-bottom: 0px;
    }

    .main-time-box .time-numer-presale-list {
        font-size: 13px;
        line-height: 26.28px;
    }

    .billionair-tokenpre-main {
        max-width: 335px;
        margin: auto;
        width: 94%;
    }

    .affliated-tree .tree-button,
    .affliated-tree .tree-button:hover {
        font-size: 10px;
        font-weight: 500;
        padding: 10px 10px;

    }

    .res-set-modal-hit {
        overflow: auto;
        max-height: calc(100vh - 30px);
        max-width: 600px !important;
        width: 95% !important;
        padding: 20px 15px !important;
    }

    .res-li-modal {
        padding: 5px 0px !important;
    }

    .input-box-admin2 .form-group {
        margin-bottom: 10px !important;
    }

    .main-time-box {
        min-width: 60px;
        padding: 0 8px;
        text-align: center;
    }

    .login-bg-content {
        display: none;
    }

    .checkbox-main span {
        font-family: 'Poppins-Regular';
        font-size: 12px;
        line-height: 21px;
    }

    .admin-header-logo {

        height: 50px;


    }


    .billionair-list-content .hero-token-presale {
        font-size: 21px;
        line-height: 35.4px;
        margin-bottom: 9.41px;
    }

    .billionair-list-content .hero-token-details-presale {
        font-size: 12px;
        line-height: 21.12px;
        margin-bottom: 9.87px;
    }

    .main-time-box .time-numer-presale {
        color: #fff;
        font-size: 29px;
        line-height: 43.6px;
        margin-bottom: 0px;
    }

    .main-time-box .time-numer-presale-list {
        font-size: 13px;
        line-height: 26.28px;
    }

    .billionair-tokenpre-main {
        max-width: 335px;
        margin: auto;
        width: 94%;
    }

    .affliated-tree .tree-button,
    .affliated-tree .tree-button:hover {
        font-size: 10px;
        font-weight: 500;
        padding: 10px 10px;

    }

    .res-set-modal-hit {
        overflow: initial;
        max-height: initial;
        max-width: 600px !important;
        width: 100% !important;
        padding: 20px 15px !important;
    }

    .res-li-modal {
        padding: 5px 0px !important;
    }

    .input-box-admin2 .form-group {
        margin-bottom: 10px !important;
    }

    .main-time-box {
        min-width: 60px;
        padding: 0 8px;
        text-align: center;
    }


    .new-time-presale-btns-list .time-presale-main-btn,
    .new-time-presale-btns-list .time-whiteppaer-main-btn {
        width: calc(100% - 0px);
    }

    .new-time-presale-btns-list {
        display: block;

    }

    .res-mb-20 {
        margin-bottom: 20px;
    }

    .bottom-bg-footer .copyright-title {
        font-size: 12px;
        line-height: 22px;
        text-align: center;
    }

    .footer-set {
        display: block;
    }

    .footer-ul li a {
        padding: 0px 10px;
        font-size: 13px;
        line-height: 22px;

    }

    .res-header-listitem-li {
        justify-content: end !important;
    }

    .token-presale-background {
        height: 100%;
        min-height: calc(100vh - 87px);
    }

    .comman-flex-justify {
        justify-content: start;
    }

    /* 6-6 start */
    .admin-header-main {
        left: 0;
    }

    /* .dashboard-left-main {
        flex: unset;
        max-width: unset;
        width: unset;
    } */

    .admin-sidebar-main {
        height: 100%;
        left: -290px;
    }

    .add-user-data-main {
        max-width: calc(100vw - 50px);
        overflow: auto;
    }

    .admin-sidebar-logo {
        padding: 9px 0;
        height: 76px;
    }

    .user-head-title {
        flex-wrap: wrap;
    }

    .admin-mobile-menu-icon {
        display: block;
        height: 20px;
    }

    .dashboard-content .admin-page-title {
        margin-bottom: 0;
    }

    .admin-tabs-main .admin-tab {
        padding: 10px 5px;
    }

    .admin-sidebar-main.active {
        left: 0;
    }

    .cross-res {
        justify-content: flex-end;
        display: flex;
        margin-top: 10px;
        position: absolute;
        top: 4px;
        right: 0;
    }

    .cross-res button {
        padding: 0;
    }


    /* 6-6 end */
    .qr-main-modal {
        width: 95% !important;
    }

    .set-modal-line:after {
        display: none;
    }

    .res-primary-btn {
        display: flex;
        margin: 25px 0px 0px;
    }

    .res-dash-referral-btn-main {
        display: block;
    }

    .footer-set {
        display: block;
    }

    .footer-ul li a {
        padding: 0px 10px;
        font-size: 13px;
        line-height: 22px;

    }

    .res-set-cross-icon {
        display: flex;
        justify-content: space-between;
    }

    .icon-setclose {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    .cancel-btn {
        height: 30px;
        width: 30px;
        border: 1px solid white;
        padding: 5px;
        border-radius: 50%;
        object-fit: contain;
    }

    .admin-sidebar-main {
        height: 100%;
        left: -300px;
    }

    .admin-sidebar-main {
        height: 100%;
        /* left: -300px; */
    }

    .dashboard-left-main {
        flex: unset;
        max-width: unset;
        width: unset;
    }



    .admin-sidebar-logo-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .billionair-tokenpre-card {
        display: none;
    }

    .res-pt-20 {
        padding-top: 20px !important;
    }

    .admin-header-logo,
    .logo-token-left {
        height: 54px;
    }

    .user-sidebar-logo-main {
        display: block !Important;
    }

    .display-none {
        display: block !important;
    }





    .admin-sidebar-logo-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .admin-side-logo-text .admin-sidebar-logo {
        max-width: 55px;
        height: 55px;
    }

    .new-time-presale-btns-list .time-presale-main-btn,
    .new-time-presale-btns-list .time-whiteppaer-main-btn {
        width: calc(100% - 0px);
    }

    .new-time-presale-btns-list {
        display: block;

    }

    .res-mb-20 {
        margin-bottom: 20px;
    }

    .bottom-bg-footer .copyright-title {
        font-size: 12px;
        line-height: 22px;
        text-align: center;
    }

    .res-header-listitem-li {
        justify-content: end !important;
    }

    .token-presale-background {
        height: 100%;
        min-height: calc(100vh - 87px);
    }

    .comman-flex-justify {
        justify-content: space-between !important;
    }

    .res-set-modal-hit h4 {
        font-size: 20px;
        line-height: 22.4px;
    }

    /* .white-paper-margin {
    margin-top: 40px;
} */
    .user-sidebar-logo {
        height: 52px !important;

    }

    .admin-header-main {
        left: 0;
    }

    /* .dashboard-left-main {
    flex: unset;
    max-width: unset;
    width: unset;
} 
/* .close-icon-modal {
    height: 32px;
    width: 35px;
    border: 1px solid white;
    border-radius: 50px;
    padding: 6px;
} */
    .admin-sidebar-logo {
        height: 0px;
        margin-top: 4px;
        max-width: 85px;

    }

    .admin-sidebar-logo-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .billionair-tokenpre-card {
        display: none;
    }

    .res-pt-20 {
        padding-top: 20px !important;
    }

    .admin-header-logo,
    .logo-token-left {
        height: 50px;
        padding: 9px 0;

    }

    .user-sidebar-logo-main {
        display: block !Important;
    }

    .display-none {
        display: block !important;
    }

    .primary-border-btn-main-header .history-btn-header {

        margin-left: 23px;
        font-family: 'Poppins-Medium';
        height: 35px !important;
        min-width: 120px;
        margin-right: 15px;
    }

    .res-set-menu-icone-btn {
        min-width: 43px !important;
    }

    .admin-header-row-icon {
        justify-content: space-between !important;
    }

    .res-logo-icon {
        display: block;
    }

    .hit-token-box {
        padding: 11px 0px 11px;
    }

    .user-sidebar-logo {
        height: 50px !important;
        max-width: 89px !important;
    }

    .user-sidebar-logo-main {
        text-align: left !important;
        height: 76.08px !important;
        background: #27170a !important;
    }


    .admin-sidebar-main {
        height: 100%;
        /* left: -300px; */
    }

    .add-user-data-main {
        max-width: calc(100vw - 50px);
        overflow: auto;
    }

    .user-head-title {
        flex-wrap: wrap;
    }



    .dashboard-content .admin-page-title {
        margin-bottom: 0;
    }

    /* 8-6 start */

    .res-join-wait-input input {
        min-width: unset !important;
    }

    .res-join-wait-input .submit-btn-main button {
        height: 36px;
        min-height: 36px;
    }

    .res-admin-logo .admin-sidebar-logo {
        height: 37px;
        width: 55px;
        margin-top: 0px;
        max-width: 85px;
        padding: 0;
        display: block;
    }

    .res-admin-logo {
        justify-content: left;
    }

    .res-admin-header {
        display: flex;
        justify-content: space-between;
    }

    .res-admin-header .logo-text {
        font-size: 11px;
    }

    .admin-side-logo-text .admin-sidebar-logo,
    .admin-side-logo-text .logo-text {
        display: none;
    }

    /* ----- */
    .res-logo-text {
        margin-left: -35px;
    }


    /* 8-6 end */
    .res-admin-header .admin-header-row button {
        justify-content: center;
    }

    .res-set-logo-home {
        margin-top: 5px;
    }

    .admin-header-row-icon .mobile-menu-btn {
        display: flex !important;
        justify-content: flex-end;
        padding-right: 10px;
        min-width: 20px;
    }

    .admin-header-logo,
    .logo-token-left-set {
        height: 50px !important;
        padding: 0px !important;

    }

    /* 12-6 start */
    .deposit-modal-scroll {
        height: calc(100vh - 30px);
        overflow: auto;
    }

    .refferaltables-main {
        width: 100%;
        margin-top: 20px;

    }

    .admin-dashboard-inner-box .user-dash-text {
        white-space: normal;
    }

    /* 12- 6-end */
    .ref-card-main {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .ref-card-inner {
        margin: 9px 6px;
        min-width: 81px;
    }

    .flex-table {
        display: block;
    }

    .res-none {
        display: none;
    }

    .desc-none,
    .res-marquee-text {
        display: block;
    }

    .flex-text {
        display: flex;
    }

    .set-code-box-main .dash-referral-btn-main .text-copy-box .text-copy {
        display: inherit;
        overflow: inherit;
    }

    .dashboard-containt-main {
        padding-top: 10px;
    }

    .res-copt-text {
        display: none !important;
    }

    .res-set-btn-deposit {
        display: flex;
        justify-content: space-between;
    }

    .res-set-btn-deposit-link {
        display: flex;
        align-items: center;
    }

    .primary-border-btn-main .res-history-btn {
        max-width: 87px;
        min-width: 82px;
        margin-left: 0px;
        font-family: 'Poppins-Medium';
        height: 30px;
        min-width: 90px;
    }

    .res-admin-dashboad-row {
        margin-top: 15px;
    }

    .primary-border-btn-main .res-primary-border-btn {
        font-size: 12px !important;
    }

    .dashboard-content .admin-page-title {
        font-size: 20px;
    }

    .set-qr-code {
        display: none;
    }

    .res-admin-header+.dashboard-containt-main {
        padding-top: 88px;
    }

    .page-table-main .table tr th,
    .page-table-main .table tr td {
        white-space: nowrap;
    }

    /* .marquee-text {
        font-size: 14px;
        font-family: 'Poppins-Regular';
        color: #fff;
        max-width: auto;
        width: auto;
    } */
    .primary-border-btn-main .upload-set-btn {
        height: 35px !important;
        min-width: 100px !important;
    }

    .admin-tabs-main .MuiTabs-scroller {
        overflow: auto !important;
    }

    /* / 22-6 start / */

    .admin-login-inner {
        padding-left: 15px;
        padding-right: 15px;
    }

    .flex-drop-main .admin-header-drop {
        font-size: 13px;
    }

    /* / 22-6 end / */
    
    .dash-referral-btn-main {
        margin-top: 20px;
        margin-bottom: 0px;
    }
    .user-btn-flex {
        margin-top: 10px;
    }
    .add-user-input {
        margin-bottom: 10px;
    }
    .doc-upload-input .kycpreview {
        position: absolute !important;
        top: 1px;
        left: 221px;
    }
    .user-sidebar-link.active {
        padding: 5px 20px 5px 36px !important;
        margin-left: 25px;
    }
    .user-dashboard-header {
        padding: 5px 20px 5px 36px !important;
    }
    
}




@media only screen and (max-width: 576px) {}

@media screen and (min-width: 320px) and (max-width:374px) {}

@media only screen and (max-width: 320px) {
    .res-gird-box {
        width: 100% !important;
    }
}