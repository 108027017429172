* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

body,
html {
  scroll-behavior: smooth;
}

.container {
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/poppins-bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Teko-Regular";
  src: url("../fonts/Teko/Teko-Regular.ttf");
}

@font-face {
  font-family: "Teko-Bold";
  src: url("../fonts/Teko/Teko-Bold.ttf");
}

@font-face {
  font-family: "Teko-Medium";
  src: url("../fonts/Teko/Teko-Medium.ttf");
}

@font-face {
  font-family: "Teko-SemiBold";
  src: url("../fonts/Teko/Teko-SemiBold.ttf");
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.primary-btn-main-user .comman-primary-btn,
.primary-btn-main-user .comman-primary-btn:hover,
.primary-btn-main-user .comman-primary-btn.active,
.primary-btn-main-link-user .comman-primary-btn,
.primary-btn-main-link-user .comman-primary-btn:hover,
.primary-btn-main-link-user .comman-primary-btn.active {
  background: #5a320a;
  border-radius: 0px;
  padding: 5px 30px;
  min-height: 45px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  text-transform: capitalize;
  box-shadow: none;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.form-control p {
  margin-left: 0px !important;
}

/* .form-group .form-control input {
    border: 1px solid #74540b42;
    width: 100%;
    padding: 9.45px 16px;
    background-color: transparent;
    border-radius: 6px;
    color: #08080A;
    font-size: 14px;
    line-height: 21px;
} */
.form-group .form-control input {
  /* border: 1px solid #74540b42; */
  border: 1px solid #d1d5db;
  width: 100%;
  padding: 9.45px 16px;
  background-color: transparent;
  border-radius: 4px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.form-group .text-custom-control {
  width: 100%;
  min-height: 138px;
  background: transparent;
  padding: 11.45px 16px;
  border-radius: 14px;
  font-family: "Poppins-Regular";
  border: 1px solid #849ec1;
  resize: none;
}

.form-group .form-control input::placeholder {
  color: #08080a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #849ec1;
  border-width: 0px;
}

fieldset {
  border-color: transparent !important;
  top: 0px !important;
}

.footer-main .custom-group .form-label,
.footer-main .form-group .form-control input::placeholder,
.footer-main .form-group .form-control input,
.footer-main .form-group .text-custom-control {
  color: #ffff;
}

/* home start */

.hero-pos-relative {
  position: relative;
  margin-top: -100px;
}

.home-inner-main {
  min-height: 802px;
  width: 100%;
  object-fit: cover;
}

.home-text-content-top .home-pd-contnet {
  padding: 50px 0px 50px 0px;
  min-height: 702px;
  display: flex;
  align-items: center;
}

.home-text-content-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 802px;
  padding-top: 100px;
  display: flex;
  align-items: center;
}

.home-content-details .home-choose-life-title {
  font-size: 32px;
  font-family: "Teko-Bold";
  color: #f5aa16;
  line-height: 45px;
  margin-bottom: 10px;
}

.home-content-details .home-start-your-fitness {
  color: #ffffff;
  max-width: 468px;
  font-size: 65px;
  font-family: "Teko-SemiBold";
  line-height: 70px;
  text-transform: uppercase;
  min-height: 161px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.home-content-details .home-distracted-long {
  color: #ffffff;
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 28px;
  margin-bottom: 33px;
}

.outline-btn-main .outline-btn-list {
  border: 1px solid #ffffff;
  border-radius: 12px;
  padding: 5px 20px;
  height: 45px;
  color: #ffffff;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
  min-width: 151px;
}

.store-main-btn-list {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.home-register-btn .primary-btn-main-user .comman-primary-btn {
  box-shadow: 0px 3px 6px #00000047;
}

.outline-btn-main .outline-btn-list .store-img-home {
  margin-right: 4.1px;
}

.store-main-btn-list .btn-list-store {
  margin-right: 10px;
}

/* home end */
.w-100 {
  max-width: 100% !important;
  width: 100% !important;
}

/* header start */
.comman-flex-justify {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-between;
}

.header-main-details {
  background: transparent;
}

.header-main-component {
  z-index: 9;
  position: relative;
}

/* .header-main-pd {
    padding: 10px 0px;
} */
.header-main-pd {
  /* padding: 50px 0px 0px; */
  padding: 30px 0 0;
}

.logo-main-left,
.logo-header {
  height: 80px;
}

.center-list-header .header-ul-list {
  display: flex;
  align-items: center;
  padding: 0px;
}

.center-list-header .header-ul-list .header-listitem-li {
  padding: 0px 20px;
  max-width: max-content;
}

.center-list-header .header-ul-list .header-listitem-li .header-redirect-link {
  text-decoration: none;
  font-size: 16px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  line-height: 23px;
}

/* header end */

/* joyful start */

.jyoful-main-content .pd-joyful-investment {
  padding: 60px 0px 159px 0px;
}

.title-details-joyful .joyful-spirit-title {
  font-size: 60px;
  line-height: 66px;
  max-width: 642px;
  margin: auto auto 67px auto;
  font-family: "Teko-SemiBold";
  text-align: center;
  color: #06060f;
  text-transform: uppercase;
}

.virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem {
  padding: 0px 0px 13.5px 0px;
  position: relative;
  margin-bottom: 57.5px;
}

.virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #051f58;
  bottom: 0;
}

.virtual-box-list .customize-joyful-details span {
  color: #e17025;
}

.virtual-box-list .customize-joyful-details {
  font-size: 21px;
  line-height: 30px;
  color: #1b2636;
  font-family: "Poppins-SemiBold";
  max-width: 300px;
  margin: 0px 11.7px 0px 11.7px;
}

.virtual-joyful-race-main
  .virtual-joyful-list
  .virtual-joyful-listitem:last-child {
  margin-bottom: 0px;
}

.joyful-race-main-img {
  text-align: center;
}

.joyful-bg-top {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
}

.pos-relative-joyful,
.referral-friend-content,
.footer-pos-main {
  position: relative;
}

.joyful-race-content {
  max-width: 553px;
  height: 677px;
  margin: auto;
}

.virtual-joyful-race-main {
  z-index: 1;
  position: relative;
}

/* joyful end */

/* Roadmap start */

.roadmapbg-main-contant {
  background-image: linear-gradient(
      180deg,
      rgb(3 32 88 / 77%) 0%,
      rgb(0 0 0 / 77%) 100%
    ),
    url("../images/png/roadmapbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.roadmap-pd-list {
  padding: 79.5px 0px 474px 0px;
}

.roadmap-title-content .shadow-contnet-title-comman {
  font-size: 89px;
  font-family: "Poppins-Bold";
  text-transform: uppercase;
  text-align: center;
  background: -webkit-linear-gradient(rgb(255 255 255 / 25%) -3%, #092257);
  /* background: -webkit-linear-gradient(rgb(255 255 255 / 25%) 22%, rgb(9 30 72 / 37%)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.28;
  line-height: 86px;
  letter-spacing: 15px;
}

.list-roadmap-details .listitem-roadmap-details:last-child {
  padding-bottom: 0px;
}

.bottom-arrow-roadmap .roadmap-arrow-bottom {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.roadmap-title-content .title-roadmap-details {
  color: #fff;
  font-family: "Teko-SemiBold";
  font-size: 60px;
  text-align: center;
  text-transform: uppercase;
  line-height: 66px;
  margin-bottom: 142.7px;
  margin-top: -21px;
}

.max-content-roadmap {
  max-width: 631.89px;
  margin: auto;
}

.road-content-max-list .rounded-road-year-flex,
.flex-btn {
  display: flex;
  align-items: center;
}

.rounded-road-year-flex .year-text-contnet {
  position: relative;
  margin-right: 12.3px;
  z-index: 9;
}

.year-text-contnet .year-title-details {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  display: flex;
  justify-content: center;
  height: 73px;
  width: 73px;
  align-items: center;
  font-size: 21px;
  font-family: "Poppins-Medium";
  color: #ffff;
  line-height: 29px;
  padding: 9px;
}

.rounded-road-year-flex .qtitle-roadmap {
  font-size: 23px;
  font-family: "Poppins-Medium";
  color: #fff;
  line-height: 32px;
}

.rounded-road-year-flex .qtitle-roadmap {
  font-size: 23px;
  font-family: "Poppins-Medium";
  color: #fff;
  line-height: 32px;
}

.list-roadmap-details .listitem-roadmap-details:nth-child(even) {
  margin-left: auto;
  justify-content: end;
}

.roadmap-details-content .roadmap-desc {
  font-size: 12px;
  font-family: "Poppins-Regular";
  line-height: 18px;
  text-align: center;
  color: #fff;
}

.roadmap-details-content {
  max-width: 231px;
  margin: -18px 44px 0px 44px;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .road-content-max-list
  .rounded-road-year-flex {
  flex-direction: row-reverse;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .year-text-contnet
  .year-title-details {
  left: 0;
  margin-right: auto;
  margin-left: initial;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .rounded-road-year-flex
  .year-text-contnet {
  margin-right: 0px;
  margin-left: 12.3px;
}

.list-roadmap-details .listitem-roadmap-details {
  padding: 0px 0px 65.1px 0px;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .roadmap-details-content {
  margin: -18px auto 0px 130px;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .bg-year-rounded
  .rounded-img {
  transform: rotate(180deg);
}

.max-content-roadmap .list-roadmap-details {
  padding: 0px;
  position: relative;
}

.max-content-roadmap .list-roadmap-details::after {
  content: "";
  background: #fff;
  height: calc(100% - 124px);
  width: 1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}

/* referral start */

.referral-main-title-box .small-referral-title {
  font-size: 60px;
  text-align: center;
  font-family: "Teko-SemiBold";
  text-transform: uppercase;
  line-height: 84px;
  margin-bottom: 8px;
  color: #000000;
  margin-top: -21px;
}

.title-referral-main .description-referral-details {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: center;
  margin-bottom: 52px;
}

.referral-friend-content {
  padding-top: 93px;
}

.flex-referral {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

img.refrrel-icon {
  width: 117px;
  height: 102px;
  object-fit: contain;
  margin-right: 13px;
}

.referral-content-details {
  border: 1px solid #849ec1;
  border-radius: 16px;
  padding: 10px 7px 17px 9px;
}

.title-reffrel-program .title-pro-refreel {
  font-size: 22px;
  line-height: 31px;
  color: #000000;
  font-family: "Poppins-SemiBold";
  margin-bottom: 4px;
}

.title-reffrel-program .prices-pro-refreel {
  font-family: "Poppins-Medium";
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.referral-content-details .referral-details-card {
  font-family: "Poppins-Regular";
  font-size: 18px;
  max-width: 490px;
  line-height: 27px;
  color: #000000;
}

.referral-card-list-details .referral-box-ul,
.virtual-joyful-race-main .virtual-joyful-list,
.address-footer-main .footer-add-ul,
.footer-social-icon-main .footer-social-icon-ul {
  padding: 0px;
}

.referral-card-list-details .referral-box-ul .referral-box-listitem {
  padding: 0px 0px 20px 0px;
}

.referral-card-list-details .referral-box-ul .referral-box-listitem:last-child {
  padding-bottom: 0px;
}

.refrral-friend-bg .refrral-mobile-img {
  max-width: 330.11px;
  height: 608px;
  object-fit: cover;
}

.refrral-friend-bg {
  margin: auto 62px auto auto;
  max-width: 330.11px;
}

.referral-friend-content::after {
  content: "";
  background-image: url("../images/svg/reffrral-bg.svg");
  position: absolute;
  top: 0;
  right: 0;
  width: 471px;
  height: 813px;
  z-index: -1;
}

.referral-grid {
  align-items: center;
}

.referral-details-main {
  padding: 73.5px 0px 179px 0px;
  overflow-x: hidden;
}

/* footer start */

.footer-main-content {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/png/footerbg.png");
  background-position: center;
}

.pd-footer-details {
  padding: 64.5px 0px 37px 0px;
}

.contact-us-details .contactus-title {
  font-size: 60px;
  text-align: center;
  color: #ffff;
  line-height: 84px;
  text-transform: uppercase;
  font-family: "Teko-SemiBold";
  margin-bottom: 25px;
  margin-top: -21px;
}

.referral-main-title-box .shadow-referral-title {
  font-size: 85px;
  font-family: "Poppins-Bold";
  text-transform: uppercase;
  text-align: center;
  background: -webkit-linear-gradient(#e3e6eb -17%, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.45;
  line-height: 86px;
  letter-spacing: 14px;
}

.contact-us-details .contactus-title-shadow {
  font-size: 89px;
  font-family: "Poppins-Bold";
  text-transform: uppercase;
  text-align: center;
  background: -webkit-linear-gradient(rgb(255 255 255 / 25%) -3%, rgb(0 15 44));
  /* background: -webkit-linear-gradient(rgb(255 255 255 / 25%) 22%, rgb(9 30 72 / 37%)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.28;
  line-height: 86px;
  letter-spacing: 15px;
}

.custom-group .form-label {
  font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Medium";
  line-height: 20px;
  margin-bottom: 11px;
}

.footer-grid .footer-column {
  padding: 0px 14px;
}

.footer-form-details .footer-grid .footer-column {
  margin-bottom: 30px;
}

.footer-form-details .footer-grid .footer-column:last-child {
  margin-bottom: 0px;
}

.footer-grid {
  margin: 0px -14px;
}

.footer-details-main .footer-title {
  color: #ffffff;
  font-family: "Poppins-SemiBold";
  line-height: 49px;
  font-size: 35px;
  margin-bottom: 20px;
}

.footer-details-main .footer-desc {
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  font-family: "Poppins-Regular";
  margin-bottom: 26px;
}

/* .footer-bg-rounded {
    background-image: url('../images/svg/footer-line.svg');
    background-repeat: no-repeat;
    max-width: 557px;
    height: 557px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
} */

.rounde-footer-inner-details {
  max-width: 356px;
  margin: auto auto auto 51px;
}

.footer-bg-rounded {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 437px;
}

.svg-rounded-border .footer-line-bg {
  max-width: 557px;
  height: 557px;
}

.svg-rounded-border {
  position: absolute;
  top: -56px;
  left: -60px;
}

.footer-social-icon-main .visit-social-title {
  font-size: 19px;
  color: #fff;
  font-family: "Poppins-SemiBold";
  margin-bottom: 18.3px;
  text-align: center;
  line-height: 27px;
}

.footer-logo {
  height: 118px;
  max-width: 168px;
  margin: auto auto 26px auto;
}

.address-footer-main .footer-add-ul .footer-add-li {
  padding: 0px 0px 8px 0px;
}

.address-footer-main .footer-add-ul .footer-add-li .add-icon-main-footer {
  height: 41px;
  width: 41px;
  background: #122341;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.address-footer-main .footer-add-ul .footer-add-li .add-footer-title {
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}

.address-footer-main {
  margin-bottom: 43px;
}

.footer-social-icon-main .footer-social-icon-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 243.82px;
  margin: auto;
}

.footer-social-icon-main .footer-social-icon-ul .footer-social-icon-li {
  padding: 0px;
  display: initial;
}

.footer-social-icon-main
  .footer-social-icon-ul
  .footer-social-icon-li
  .social-redirect-link {
  height: 42.21px;
  width: 42.21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.footer-top-minus {
  position: absolute;
  top: 0;
  z-index: 9;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer-pos-main {
  padding-top: 53px;
}

.send-footer-btn .comman-primary-btn {
  min-width: 161px;
}

.signin-btn-main .comman-primary-btn {
  min-width: 100%;
}

.send-footer-btn {
  text-align: end;
  padding-top: 16px;
}

.bottom-bg-footer {
  /* background-color: #F5AA16; */
  background: #4e270c;
  padding: 2px 0px;
}

.bottom-bg-footer .pd-footer-copy {
  /* padding: 19px 0px 18px 0px; */
  /* text-align: center; */
}

.bottom-bg-footer .copyright-title {
  font-size: 16px;
  line-height: 26px;
  color: #ddd;
  font-family: "Poppins-Medium";
}

/* login start */
.login-bg-content {
  background-image: url(../images/png/bgimg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-bg-content {
  background-image: linear-gradient(
      45deg,
      rgb(3 32 88 / 60%),
      rgb(3 32 88 / 60%)
    ),
    url("../images/png/signupbg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-logo-details .logo-login {
  /* max-width: 212px;
    height: 150px; */
  max-width: 252px;
  height: 200px;
}

.login-form-details .login-max-content {
  max-width: 350px;
  margin: auto;
}

.signup-form-details .signup-max-details {
  max-width: 405px;
  margin: auto;
}

.login-form-details .login-details-title {
  /* margin-bottom: 40px; */
  margin-bottom: 20px;
}

.login-details-title .login-title {
  font-size: 56px;
  font-family: "Teko-SemiBold";
  color: #5a320a;
  line-height: 90px;
}

.login-details-title .login-desc {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #08080a;
  line-height: 21px;
}

.pass_group_main {
  position: relative;
}

.pass_group_main .pass_position {
  margin-left: 0px;
}

.pass_group_main .custom_form_control {
  padding-right: 0px;
  width: 100%;
}

.pass_group_main .custom_form_control input {
  padding-right: 45px !important;
}

.pass_group_main .icon_end_icon {
  position: absolute;
  right: 16px;
}

.mb-20px {
  margin-bottom: 20px;
}

.checkbox-main {
  text-align: center;
  display: flex;
  align-items: center;
}

.checkbox-main .custom-check span {
  border: 1px solid #032058;
  border-radius: 4px;
}

.checkbox-main .custom-check {
  margin-right: 8px;
  padding: 0px;
}

.checkbox-main span {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
}

.create-account-text .signup-redirect {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  text-decoration: none;
  color: #08080a;
}

.flex-check-login {
  margin-top: -10px;
  margin-bottom: 40px;
}

.account-link-details .already-account-title {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  color: #08080a;
}

.account-link-details .already-account-title .redirect-account-link {
  color: #0371f1;
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  margin-left: 5px;
}

.account-link-details {
  margin-top: 20px;
}

.signin-btn-main {
  margin-top: 20px;
}

.login-grid-inner .login-column {
  padding: 0px 7.5px;
}

.login-grid-inner {
  margin: 0px -7.5px;
}

/* .login-pd-main {
    padding: 50px;
} */
.login-pd-main {
  padding: 0px;
}

/* login end */

.burgur-icon-main {
  margin-left: 10px;
  display: none;
}

.burgur-icon-main .outline-btn-list {
  min-width: inherit;
  height: 40px;
  padding: 5px 15px;
}

.burgur-icon-main .outline-btn-main .outline-btn-list .store-img-home {
  margin-right: 0px;
}

.overlay-section {
  display: none;
}

.input-mb-20px {
  margin-bottom: 20px;
}

/* presale round */
.referral-main-component {
  background-image: url(../images/png/1239296.png);
  background-size: cover;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}

.billionair-tokenpre-main {
  max-width: 430px;
  margin: auto;
}

/* .billionair-tokenpre-card {
    background: #1c1d23;
    border-radius: 40px;
    box-shadow: inset 2px 2px 3px hsla(0, 0%, 100%, 0.2);
    overflow: hidden;
    position: relative;
  } */
.billionair-tokenpre-card .billionair-tokenpre-body {
  /* padding: 48px 51px 48px 48px; */
  padding: 40px;
  position: relative;
  z-index: 1;
}

.billionair-list-content .hero-token-presale {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #fff;
  display: -webkit-box;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 900;
  font-size: 28px;
  letter-spacing: -0.03em;
  line-height: 31.4px;
  margin-bottom: 15px;
  overflow: hidden;
  text-align: center;
}

.billionair-list-content .hero-token-details-presale {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: hsla(0, 0%, 100%, 0.7);
  display: -webkit-box;
  line-height: 1.3;
  font-weight: 100;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  overflow: hidden;
}

.time-flex-list {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.main-time-box {
  min-width: 78px;
  padding: 0 17px;
  text-align: center;
}

.main-time-box .time-numer-presale {
  color: #fff;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 34.6px;
  margin-bottom: 0px;
}

.main-time-box .time-numer-presale-list {
  color: hsla(0, 0%, 100%, 0.5);
  font-family: "Poppins", Arial, sans-serif;
  font-size: 12px;
  line-height: 26.28px;
}

.time-presale-btns-list {
  margin: auto;
  max-width: 341px;
}

.time-presale-main-btn {
  /* margin-bottom: 21px; */
  margin-bottom: 0;
}

.btn-main-light-list .btn-light-comman,
.primary-btn-main .btn-dark-comman,
.primary-btn-main .btn-light-comman {
  border: none;
  /* border-radius: 12px; */
  box-shadow: 0 4px 64px rgba(0, 0, 0, 0.25);
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 500;
  padding: 9px 20px 12px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  line-height: 20px;
}

.btn-main-light-list .btn-light-comman,
.primary-btn-main .btn-light-comman {
  background: linear-gradient(270deg, #ffd178 9.13%, #ffbe9f 91.81%);
  color: #1c1d23;
  text-transform: none;
}

.primary-btn-main .btn-dark-comman {
  /* background: linear-gradient(90deg, #1c0d06d4 17.63%, #956c0d 85.65%); */
  color: #fff;
  text-transform: capitalize;
  background: #74540b;
  border: 1px solid #ffffff7d;
}

.carntertainment-flex {
  width: 100%;
  max-width: 1490px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.carntertainment-flex {
  width: 100%;
  max-width: 1490px;
  margin: 0 auto;
  justify-content: space-between;
}

.time-progress-bar-main {
  height: 14px;
  background: #ffffff38;
  max-width: 326px;
  margin: 0 auto 24.33px;
  border-radius: 100px;
  display: flex;
}

/* 30-5 */
.token-presale-background {
  background-image: url(../images/png/bgimg.png);
  background-size: cover;
  padding: 37px 0 30px;
  /* display: flex; */
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 126px);
}

.affliated-tree .tree-button,
.affliated-tree .tree-button:hover {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  background: #74540b;
  min-height: 40px;
  height: 40px;
  border-radius: 0px;
  text-transform: capitalize;
  border: 1px solid #fff;
}

/* .token-presale .header-main-component {
    display: none;
} */

.position-relative {
  position: relative;
}

.pos-topright {
  position: absolute !important;
  top: 0px;
  right: 30px;
}

.billionair-tokenpre-card {
  /* background: #fff; */
  /* border-radius: 40px; */
  /* box-shadow: inset 2px 2px 3px hsla(0, 0%, 100%, 0.2); */
  overflow: hidden;
  position: relative;
  /* background: linear-gradient(90deg, rgb(118 85 11), #523908); */
  border-radius: 0;
  background: linear-gradient(90deg, #604309, #56280b);
}

span.time-progress-bar {
  height: 14px;
  background: #fff;
  width: 20%;
  border-radius: 100px;
}

.btn-light-comman {
  text-transform: none !important;
  /* background: linear-gradient(270deg, #48360d 9.13%, #705137 91.81%) !important; */
  background: #74540b !important;
  color: #ffffff !important;
  border: 1px solid #ffffff82 !important;
}

.new-time-presale-btns-list {
  display: flex;
  justify-content: center;
}

.footer-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-set ul {
  display: flex;
}

.footer-ul li {
  white-space: nowrap;
  padding: 0px;
}

.footer-ul li a {
  padding: 0px 10px;
  text-decoration: none;
  font-size: 16px;
  line-height: 26px;
  color: #ddd;
  font-family: "Poppins-Medium";
}

.footer-ul li a:hover {
  /* color:#74540b; */
  color: #ad8528;
}

.primary-btn-main .btn-dark-comman,
.primary-btn-main .btn-light-comman,
.affliated-tree button {
  background-image: linear-gradient(
    30deg,
    #74540b 50%,
    transparent 50%
  ) !important;
  background-size: 751px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  cursor: pointer;
}

.primary-btn-main .btn-dark-comman:hover,
.primary-btn-main .btn-light-comman:hover,
.affliated-tree button:hover {
  background-position: 100% !important;
  color: #fff !important;
  border-color: #fff !important;
  background-color: transparent !important;
  /* background-color: #ffffff1c !important; */
}

.new-time-presale-btns-list .time-presale-main-btn,
.new-time-presale-btns-list .time-whiteppaer-main-btn {
  width: calc(50% - 10px);
}

.token-presale .header-main-component .header-main-details {
  background: transparent;
}

.token-presale .header-main-component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.logo-main-left-logo {
  margin-top: -38px;
}

.admin-header-logo,
.logo-token-left {
  height: 50px;
}

.res-set-modal-hit {
  padding: 15px 25px !important;
  border-radius: 14px;
}

.res-set-modal-hit li {
  padding: 8px 0px !important;
}

.input-box-admin2 .form-group {
  margin-bottom: 0px !important;
}

.res-set-modal-hit h4 {
  color: #74540b;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 900;
  font-size: 28px;
  letter-spacing: -0.03em;
  line-height: 31.4px;
  text-align: center;
  margin: 10px 0px 30px !important;
}

.set-grid-modal {
  padding-top: 5px !important;
}

.primary-btn-main-comman {
  cursor: pointer;
}

.primary-btn-main-comman .btn-dark-comman-main {
  /* background: linear-gradient(90deg, #1c0d06d4 17.63%, #956c0d 85.65%); */
  color: #fff;
  text-transform: capitalize;
  /* background: #74540b; */
  background: #5a320a !important;
  border: 1px solid #ffffff7d;
  /* background-image: linear-gradient(30deg, #74540b 50%, transparent 50%) !important; */
  background-image: linear-gradient(
    30deg,
    #5a320a 50%,
    transparent 50%
  ) !important;
  /* background-size: 772px!important; */
  background-size: 894px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  /* pointer-events: auto !important; */
  border: 1px solid #ffffff7d;
  background-image: linear-gradient(
    30deg,
    #5a320a 50%,
    transparent 50%
  ) !important;
  background-size: 772px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
}

.primary-btn-main-comman .btn-dark-comman-main:hover {
  background-position: 100% !important;
  color: #5a320a !important;
  border-color: #7c5e1857 !important;
  background-color: transparent !important;
  /* background-color: #ffffff1c !important; */
}

.dashboard-left-main {
  flex: 0 0 290px;
  max-width: 290px;
  flex: 0 0 260px;
  max-width: 260px;
  width: 100%;
  transition: 0.5s all;
}

.dashboard-right-main {
  flex: 1 1;
}

.dashboard-containt-main {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 88px 24px 24px 24px;
}

.dashboard-main {
  display: flex;
  width: 100%;
}

.dashboard-content .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-family: "Poppins-SemiBold";
  margin-bottom: 20px;
}

.admin-dashboard-inner-box {
  border: 1px solid #d1d5db;
  max-width: 100%;
  width: 100%;
  height: 90px;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-dashboard-inner-box .admin-dash-text {
  color: #6b7280;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

.admin-dashboard-inner-box .admin-dash-price {
  color: #111827;
  font-size: 30px;
  line-height: 46px;
  font-family: "Poppins-Medium";
}

.dash-icons {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.page-table-main .table-container {
  padding-bottom: 7px;
  box-shadow: none;
}

.page-table-main .table {
  margin: 0px 19px;
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  font-family: "Poppins-Medium";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
}

.page-table-main .table .table-td:first-child {
  color: #111827;
}

.page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 14%;
}

.prasale-tot-transaction .table .table-th:nth-child(1) {
  width: 20%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  width: 25%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(6),
.page-table-main .table .table-td:nth-child(6) {
  /* width: 15%; */
  width: 18%;
}

.admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.view-btn-main .view-order-btn {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
}

.view-btn-main .view-order-btn:hover {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
  background-color: #114a65;
  color: #ffffff;
}

.admin-dashboard-table-row {
  border-bottom: 1px solid #d1d5db;
  padding: 20px 19px;
}

.admin-dash-box {
  border-radius: 8px;
  border: 1px solid #d1d5db;
  padding: 0px 0px 20px 0px;
}

/* .admin-dashboad-row {
  margin-bottom: 24px;
} */

.display-row > * {
  padding: 0px 8px;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}

.dashboard-right-main {
  flex: 1 1;
}

/* user css start */
.user-dashboard-inner-box {
  /* background: linear-gradient(90deg, #604309, #56280b) !important; */
  background: linear-gradient(110deg, #604309e0, #491f05e8) !important;
  box-shadow: 0 0 5px rgb(0 0 0 / 22%) !important;
  border: none !important;
  height: 100px !important;
  margin-bottom: 15px;
}

.dash-icons {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

.dash-icon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: hsla(0, 0%, 100%, 0.2);
  border-radius: 50px;
  flex: 0 0 40px;
}

.user-disply {
  display: none;
}

.user-dashboard-header {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: black !important;
  display: flex;
  padding: 5px 20px 5px 81px !important;
  margin-left: 25px;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.user-sidebar-main {
  /* background: linear-gradient(157deg, #05225c, #2c59b1 553%); */
  height: 100vh;
  left: 0;
  overflow: auto;
  padding: 0px 0px !important;
  position: fixed;
  top: 0;
  /* width: 290px; */
  width: 260px;
  z-index: 999;
  border-right: none !important;
  background: #ffffff;
}

.admin-dashboard-inner-box .user-dash-text {
  color: #ffffff !important;
  font-size: 14px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  white-space: nowrap;
}

.admin-dashboard-inner-box .user-dash-price {
  color: #ffffff !important;
  font-size: 18px;
  line-height: 40px;
  font-family: "Poppins-Medium";
}

.user-sidebar-logo {
  height: 50px !important;
  max-width: 79px !important;
}

.user-header-main {
  background: #27170a !important;
  position: fixed;
  top: 0px;
  /* left: 290px; */
  left: 260px;
  right: 0px;
  z-index: 9;
  min-height: 58px;
  padding: 11px 20px 11px 20px !important;
  border-bottom: 1px solid #e5e7eb;
}

.user-sidebar-logo-main {
  text-align: left !important;
  height: 57.08px;
  background: #27170a !important;
}

.affliated-tree .tree-button-user,
.affliated-tree .tree-button-user:hover {
  min-height: 30px !important;
  height: 30px !important;
}

/* .user-dashboard-table-row{
    border-bottom: none !important;
} */
.dash-token-value-box {
  height: 31px;
  padding: 10px;
  background: #e7e7e7;
  border-radius: 5px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-token-value-box .user-value-box {
  font-size: 17px;
  line-height: 23px;
}

.referral-box {
  display: flex;
  padding: 8px 15px 0px;
  justify-content: space-between;
}

.referral-box-code {
  padding-right: 30px;
}

.admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-bold";
}

.admin-sub-title-main-user .admin-sub-title {
  color: #111827;
  font-size: 17px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.user-dash-box {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.dash-referral-btn-main {
  margin-top: 20px;
  margin-bottom: 21px;
}

.display-flex {
  display: flex;
}

.dash-text-copy-box {
  margin-right: 10px;
}

.text-copy-box {
  padding: 11px 15px 12px;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  /* max-width: 588px; */
  max-width: 600px;
  width: 100%;
}

.text-copy-box .text-copy {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-family: "Poppins-Medium";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: #5b350a;
  padding: 9px 20px;
  font-size: 16px;
  line-height: 19px;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid white !important;
  text-align: center;
  justify-content: center;
}

.text-decoration {
  text-decoration: none !important;
}

.primary-border-btn-main .history-btn {
  min-width: 70px;
}

.primary-border-btn-main .history-btn {
  max-width: 105px;
  min-width: 105px;
  margin-left: 10px;
  font-family: "Poppins-Medium";
  height: 40px;
}

.primary-border-btn-main .primary-border-btn {
  border: 1.5px solid #5a320a;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  text-transform: capitalize;
  font-family: "Poppins-Medium";
  background: #5a330a;
  cursor: pointer;
}

.primary-border-btn-main .history-btn {
  max-width: 87px;
  min-width: 82px;
  /* margin-left: 23px; */
  font-family: "Poppins-Medium";
  height: 40px;
  /* min-width: 170px; */
  min-width: 108px;
}

.set-code-box-main {
  padding: 10px 20px;
}

.logout {
  height: 18px;
  width: 20px;
}

.admin-sidebar-list-main-user {
  margin-top: 35px !important;
}

.set-qr-code {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 5-6 start */
.form-box {
  background: #fff;
}

.join-wait-input input {
  padding: 7px 15px !important;
  border-radius: 4px !important;
}

.join-hit li {
  padding: 5px 0 !important;
}

.white-paper-margin {
  /* margin-top: 111px; */
  margin-top: 93px;
}

.logo-token {
  margin-top: 0 !important;
}

.header-flex-content {
  padding: 15px 30px;
  background: #27170a;
}

.copy-btn {
  background: #5a330a;
  border-radius: 5px;
  display: flex !important;
}

.copy-btn-set {
  margin-left: 10px;
}

.copy-btn button svg path {
  fill: white;
}

.set-qr-btn-modal .MuiBox-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px !important;
  padding: 40px 40px;
  background: white;
  border-radius: 8px;
  border: 1px solid #d1d5db !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
}

.qr-main-modal {
  width: 700px !important;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d1d5db !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  padding: 50px 32px !important;
}

.qr-modal-set .MuiBox-root {
  position: unset !important;
  top: 50%;
  left: 50%;
  transform: initial !important;
  max-width: 250px !important;
  padding: 6px 20px;
  background: white;
  margin: auto;
}

.set-modal-line:after {
  content: "";
  background: #7f7f7f4a;
  top: 0px;
  /* letter-spacing: 0px; */
  right: -28px;
  width: 2px;
  height: 100%;
  display: flex;
  position: absolute;
}

.set-modal-line {
  position: relative;
}

.mt-25 {
  margin-top: 25px;
}

.qr-btn {
  /* margin-top: 30px; */
  margin-top: 16px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.modal-text {
  font-size: 14px !important;
  font-family: "Poppins-Medium" !important;
  color: black !important;
  line-height: 20px !important;
}

.withdraw-note {
  font-size: 13px !important;
  font-family: "Poppins-Medium" !important;
  color: black !important;
  line-height: 20px !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: white;
  border: 1px solid #d4d9e1;
  border-radius: 6px;
  height: 40px;
}

.input-design-div .main-select-box {
  width: 100%;
  border: 1px solid #74540b42;
  margin: 15px 0px;
  border-radius: 6px;
}

.input-design-div .MuiSelect-outlined {
  padding: 5.5px 14px;
}

.input-design-div em {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

/* 5-6 end */
/* 6-6 start */
.setting-card {
  box-shadow: 0px 3px 6px #8888887a;
  padding: 15px;
  border-radius: 7px;
  min-height: calc(100vh - 120px);
}

.admin-header-profile-box button {
  padding: 0;
}

.setting-content .MuiGrid-container {
  margin-top: 0;
}

/* .admin-mobile-menu-icon {
    display: block;
}

.admin-sidebar-main {
    height: 100%;
    left: 0px;
}

.admin-sidebar-main.active {
    left: -290px;
} */
.cross-res {
  display: none;
}

.close-modal {
  height: 31px;
  width: 32px;
  border-radius: 50px;
  padding: 8px 9px;
  /* / border: 1px solid black; / */
  display: flex;
  position: absolute;
  /* / left: 0; / */
  right: -19px;
  top: -34px;
}

.close-icon-modal {
  height: 13px;
  width: 13px;
}

.colse-position {
  position: relative;
}

/* 6-6 end */

.display-none {
  display: none !important;
}

.icon-setclose {
  display: none;
}

.res-primary-btn {
  display: flex !important;
}

.set-hover-text:hover {
  cursor: pointer;
  /* background-color: red; */
  color: #bf5a08;
}

.btn-main-submit .btn,
.btn-main-submit .btn:hover {
  cursor: pointer !important;
  pointer-events: auto !important;
  color: rgb(255 255 255 / 43%) !important;
}

.primary-border-btn-main-header .history-btn-header {
  max-width: 87px;
  min-width: 82px;
  margin-left: 3px;
  font-family: "Poppins-Medium";
  height: 35px !important;
  min-width: 170px;
  margin-right: 15px;
}

.set-logo-text-header {
  display: flex;
  align-items: center;
}

.res-logo-icon {
  display: none;
}

.set-box-line:after {
  content: "";
  background: #7f7f7f4a;
  top: 68px;
  bottom: 15px;
  /* letter-spacing: 0px; */
  right: 50%;
  width: 2px;
  height: calc(100% - 100px);
  display: flex;
  position: absolute;
}

.set-box-line {
  position: relative;
}

table.referral tr th.border,
table.referral tr td.border {
  border-right: 1px solid #e0e0e0;
}

.set-home-logo {
  display: flex;
  align-items: center;
}

.home-logo-text {
  margin-left: 10px;
}

/* .admin-sidebar-link.active {
    padding: 5px 20px 5px 81px !important;
  } */

.user-sidebar-link.active {
  padding: 5px 20px 5px 81px !important;
  margin-left: 25px;
}

.flex-table {
  display: flex;
  /* justify-content: space-between; */
  /* justify-content: space-between; */
  /* margin-right: -10px; */
  /* margin-left: -10px; */
}

.referral-list-tree-btn {
  display: flex !important;
  justify-content: space-between !important;
}

.qrcode-title {
  color: #111827 !important;
  font-size: 23px !important;
  line-height: 30px !important;
  font-family: "Poppins-SemiBold" !important;
  text-align: center !important;
  margin-bottom: 20px !important;
}

.spin-loader {
  display: flex;
  justify-content: center;
}

/* 12-6 start */
.refferaltables-main .referral {
  min-width: 100%;
}

.refferaltables-main {
  width: 50%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.refferaltables-main .MuiPaper-elevation {
  box-shadow: none !important;
  border-radius: 0;
}

.referral-right .set-hover-text {
  border-right: 0 !important;
}

.referral tr th {
  font-family: "Poppins-SemiBold";
  background: #c1c1c159;
}

/* logo animation */
/* .logo-anim {
    transition: .5s;
    animation: flipadnim 5s 0s both;
} */
/* 
@keyframes flipadnim {
    0% {
        -webkit-transform: rotateY(-1turn) scale(0);
        transform: rotateY(-1turn) scale(0);
    }

    100% {
        -webkit-transform: rotateY(1turn) scale(1);
        transform: rotateY(1turn) scale(1);
    }
} */
.logo-anim {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.roundmainimg,
.roundmainimg3 {
  -webkit-animation: rotating 12s linear infinite;
  animation: rotating 12s linear infinite;
}

.roundmainimg2 {
  width: 291px;
  height: 280px;
  position: absolute;
  -webkit-animation: rotating1 12s linear infinite;
  animation: rotating1 12s linear infinite;
}

.roundmainimg {
  width: 290px;
  height: 290px;
  object-fit: cover;
  position: absolute;
}

.hero-bgimage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 9px;
  left: 0;
  object-fit: contain;
}

.roundmainimg3 {
  position: absolute;
  width: 247px;
  height: 234px;
}

@keyframes rotating {
  0% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes crescendo {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.3);
    letter-spacing: 5px;
  }
}

/* logo animation  */
.modal-text-copybox {
  max-width: 100%;
  width: 82%;
}

.admin-dashboard-box2 .user-dashboard-inner-box {
  height: 100px !important;
  margin-bottom: 15px;
  transition: all 0.5s;
}

.admin-dashboard-box2 .user-dashboard-inner-box:hover {
  transform: scale(1.04);
}

.tree-center {
  display: flex;
  justify-content: center;
}

.primary-btn-main-link-user {
  text-decoration: none !important;
}

/* 12-6 end */

.checkbox-main .checkbox-lable {
  display: flex;
  align-items: flex-start;
}

.checkbox-lable .MuiFormControlLabel-label {
  text-align: left;
}

.f-direction {
  flex-direction: column;
}

.login-logo-title {
  color: #fff;
  padding-top: 40px;
  font-size: 28px;
  font-family: "Poppins-SemiBold";
  animation: crescendo 1.5s alternate infinite ease-in;
}

/*  */

.ref-card-main {
  width: 50%;
  border: 1px solid #e0e0e0;
  margin: 0 13px;
}

.ref-title {
  background: #5a320a;
  color: #fff;
  padding: 10px;
  font-family: "Poppins-SemiBold";
}

.ref-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  overflow: auto;
  max-height: 408px;
}

.ref-card-inner {
  background: #f1eded;
  margin: 8px;
  padding: 5px 6px;
  min-width: 75px;
  border: 1px solid #d3cccc;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  font-family: "Poppins-Regular";
  font-size: 14px;
  cursor: pointer;
}

.redirect-home-link {
  text-decoration: none;
}

/* 15-6 start */
.marquee-text {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #fff;
  max-width: 540px;
  width: 550px;
}

.res-none {
  display: inline-flex;
}

.header-right {
  display: flex;
  align-items: center;
}

.flex-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resposive-dropdown .text-decoration .resposive-dropdown-main {
  background: transparent;
}

.resposive-dropdown
  .text-decoration
  .resposive-dropdown-main
  .primary-border-btn {
  background: transparent;
  color: #000;
  border: 1px solid;
  padding: 13px 20px;
  border: 0;
  border-bottom: 1px solid #74540b;
  border-radius: 0;
}

.resposive-dropdown
  .text-decoration:last-child
  .resposive-dropdown-main
  .primary-border-btn {
  border-bottom: 0;
}

.setting-iconsvg {
  height: 30px;
}

.res-marquee-text {
  color: #74540b;
  padding-top: 95px;
  display: none;
}

.name-copy-qr button {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 20px;
  text-transform: capitalize;
  font-family: "Poppins-Medium";
  background: #5a330a;
  cursor: pointer;
  min-width: 137px;
}

.flex-text {
  display: none;
}

.res-set-btn-deposit-link {
  display: none;
}

/* 15-6 end */

.resend-main {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 10px 0px !important;
  font-family: "Poppins-Medium";
}

.resend-button a {
  text-decoration: none;
  margin-left: 9px;
  letter-spacing: -0.3px;
  color: #74540b;
}

.primary-btn-main-comman .deposit-btn-dark-comman-main:hover {
  background-position: 100% !important;
  color: #fff !important;
  border-color: #7c5e1857 !important;
  background-color: transparent !important;
  /* background-color: #ffffff1c !important; */
}

.primary-btn-main-comman .deposit-btn-dark-comman-main {
  /* background: linear-gradient(90deg, #1c0d06d4 17.63%, #956c0d 85.65%); */
  color: #fff;
  text-transform: capitalize;
  background: #5a330a !important;
  border: 1px solid #ffffff7d;
  background-image: linear-gradient(
    30deg,
    #5a320a 50%,
    transparent 50%
  ) !important;
  /* background-size: 772px!important; */
  background-size: 894px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  /* pointer-events: auto !important; */
  border: 1.5px solid #5a320a;
  background-image: linear-gradient(
    30deg,
    #5a320a 50%,
    transparent 50%
  ) !important;
  background-size: 772px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
}

.primary-btn-main-comman .deposit-link-btn-dark-comman-main:hover {
  background-position: 100% !important;
  color: #5a320a !important;
  border-color: #7c5e1857 !important;
  background-color: transparent !important;
  /* background-color: #ffffff1c !important; */
  font-family: "Poppins-Medium";
}

.primary-btn-main-comman .deposit-link-btn-dark-comman-main {
  /* background: linear-gradient(90deg, #1c0d06d4 17.63%, #956c0d 85.65%); */
  color: #fff;
  text-transform: capitalize;
  background: #5a330a !important;
  border: 1px solid #ffffff7d;
  background-image: linear-gradient(
    30deg,
    #5a320a 50%,
    transparent 50%
  ) !important;
  /* background-size: 772px!important; */
  background-size: 894px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  /* pointer-events: auto !important; */
  border: 1.5px solid #5a320a;
  background-image: linear-gradient(
    30deg,
    #5a320a 50%,
    transparent 50%
  ) !important;
  background-size: 772px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
}

.otp-flex-main2 {
  max-width: 346px;
  margin: auto;
  width: 100%;
}

.set-count-box-top {
  margin-top: 10px;
}

.transaction-right {
  display: flex;
}

.transaction-right .admin-text-field-main {
  margin-right: 20px;
}

/* / 22-6 start / */
.transaction-table-main2 .table .table-th:nth-child(2),
.transaction-table-main2 .table .table-td:first-child {
  width: 50% !important;
}

.transaction-table-main2 .table .table-th:nth-child(2),
.transaction-table-main2 .table .table-td:nth-child(2) {
  width: 50% !important;
}

.transaction-table-main2 .table {
  min-width: 250px !important;
}

.page-table-main tr td.set-table-td {
  text-align: center !important;
}

.date-range-picker .css-e83jn5-MuiStack-root > .MuiTextField-root {
  min-width: 252px !important;
}

/* /  css start [KYC Details] / */
.kyc-input-details .dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.kyc-input-details .dropdown-box .form-control .dropdown-select {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  width: 100%;
  background-color: transparent;
}

.kyc-input-details .dropdown-select .MuiSelect-select {
  font-family: "Poppins-Regular";
  display: flex;
  padding: 7px 16px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.kyc-input-details .form-group .form-control fieldset {
  border: 0px;
  border-width: 0px;
  border-color: transparent;
}

.kyc-input-details .MuiMenu-list .menuitem {
  font-size: 14px;
}

.kyc-row-main .kyc-grid-column {
  padding: 0px 15px;
  margin-bottom: 15px;
}

.kyc-row-main {
  margin: 0px -15px;
}

.kyc-input-details .form-lable {
  font-size: 13px;
  line-height: 18px;
  font-family: "Poppins-Medium";
  color: black !important;
  margin-bottom: 5px;
}

.title-name-upload {
  margin-bottom: 10px;
}

.title-name-upload label,
.title-name-upload label:hover {
  width: 100%;
  background: transparent;
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid #d1d5db;
  box-shadow: none;
  color: #d1d5db;
  flex-direction: column;
  height: 300px;
}

.title-name-upload label span {
  margin-top: 10px;
}

.right-submit-btn {
  display: flex;
  align-items: end;
  justify-content: end;
}

.kyc-card-hgt {
  min-height: initial;
}

.upload-img-content {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  position: relative;
  display: flex;
}

.upload-img-content .upload-kyc-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.close-kyc-main {
  background: white;
  box-shadow: 0px 3px 6px #8888887a;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0px;
}

.close-kyc-main img {
  height: 20px;
  width: 20px;
}

/* /css end [KYC End] / */

.country-code-main .country-code-details {
  border: 1px solid #d1d5db;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  color: #08080a;
}

.country-code-main .country-code-details .MuiInput-underline:before {
  display: none !important;
}

.country-code-main .country-code-details .MuiInput-underline:after {
  border-bottom: 0px !important;
}

.country-code-main .country-code-details input {
  padding-left: 0px !important;
  font-size: 14px;
  line-height: 21px;
}

.country-code-add-user input {
  padding: 9.45px 16px !important;
}

.doc-upload-input {
  display: flex;
  /* justify-content: space-between; */
  position: relative;
}

.doc-upload-input .kycpreview {
  position: absolute !important;
  top: 0;
  left: 290px;
}

.multiline-input {
  border: 1px solid #d1d5db !important;
}

.support-btn-flex {
  margin-top: 10px !important;
}

.login-user-form {
  position: relative;
}

.flaot-support-btn {
  position: "absolute";
  bottom: -50px;
  left: 560px;
}

/* meet css */
.tab-one-dash .css-19kzrtu {
  padding: 10px 0 0 0 !important;
}

.admin-dash-main .css-1gsv261 {
  border: none;
}

.tab-two-dash .css-19kzrtu {
  padding: 10px 0 0 0 !important;
}

.tab-label-dash .MuiTabs-scroller .MuiTabs-flexContainer button {
  font-size: 14px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

.tab-label-dash .css-1aquho2-MuiTabs-indicator {
  background-color: #bda07e !important;
}

.tab-label-dash .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #572c0a;
}

.main-stack-tab-one .css-19kzrtu {
  padding: 10px 0 0 0 !important;
}

/* meet css end */
/* kyc response  */

.response-main-card {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.response-main-card img {
  width: 400px;
  height: 400px;
}

.response-main-card h3 {
  text-align: center;
  margin: 10px 0px;
}

.kyc-edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.title-admin-drop {
  display: flex;
  align-items: center;
}

.title-admin-drop span {
  margin-left: 5px;
  font-size: 5px;
}

/* 7-7 start */
.set-stack-btn {
  display: flex;
}

.stack-box-btn {
  display: flex;
  justify-content: space-between;
}

.set-qr-code-new {
  height: 100px;
  display: initial !important;
}

.set-stack-modal {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.primary-border-btn-main .history-btn .history-btn-stack {
  height: 35px !important;
}

.sub-title-stack .admin-sub-title {
  color: #111827;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Medium";
}

.sub-title-stack .admin-sub-title-stack {
  color: #111827;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Bold";
  margin-bottom: 10px;
}

.Withdraw-modal {
  padding: 5px;
}

.Withdraw-modal .withdraw-main {
  margin-bottom: 10px;
}
.Withdraw-modal .Withdraw-header {
  text-align: center;
}
.stack-modal-wid .Withdraw-header h5 {
  font-size: 25px;
  font-family: "Poppins-Medium";
  text-align: center;
  padding-bottom: 5px;
}
.stack-modal-wid .Withdraw-header {
  /* border-bottom: 1px solid #000; */
  padding: 35px 25px;
}

.stack-modal-wid .Withdraw-modal {
  /* padding: 15px; */
  padding: 0px !important;
}

.Withdraw-group-btn {
  display: flex;
  justify-content: center;
  /* width: 50%; */
  margin-top: 10px;
}

.Withdraw-modal .content {
  margin-top: 15px;
}

.stack-modal-wid {
  border: none !important;
  border-radius: 8px;
  position: relative;
}
.with-head-both {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .Withdraw-para-price {
  margin-left: 14px;
} */
.Withdraw-para-price {
  /* margin-left: 14px; */
  display: flex;
  margin-top: 10px;
}
.Withdraw-para-price-set {
  border-right: 1px solid;
  padding-right: 10px;
  margin-right: 10px;
}
.withdraw-main-text {
  font-size: 13px;
  font-family: "Poppins-Regular";
  line-height: 15px;
}
.withdraw-main-line {
  margin-left: 10px !important;
}
.with-cont {
  padding-top: 10px;
}
.stack-other-model .Withdraw-header {
  padding: 10px 0 0 0;
}
.stack-other-model .main-select-box {
  margin: 5px 0 0 0;
}
.stack-other-model .input-box .modal-text {
  margin: 0 !important;
}
.stack-other-model .input-box .form-group {
  margin-top: 5px !important;
}
.stack-other-model .content .primary-border-btn-main button {
  margin-left: 0 !important;
}
.stack-other-model .Withdraw-header h5 {
  text-align: start;
  padding-left: 15px;
  padding-bottom: 0;
}
.stack-other-model .Withdraw-modal {
  padding-top: 10px;
}
.close-modal {
  position: absolute;
  top: -2px;
  right: 10px;
}
.stack-other-model .close-modal {
  top: 6px;
}
.btn-m-auto .history-btn {
  margin: auto !important;
}
.from_login_details .flex-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.verify-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}
.verify-user .verify-link {
  color: blue !important;
}
.verify-user .verify-success {
  color: green !important;
}
.verify-user .verify-token {
  color: #5a320a !important;
}

.stack-details {
  width: 100% !important;
}

.roi-year-intereset {
  margin: 5px 10px;
  border: 1px solid lightgray;
}

.roi-table-main .table .table-th:nth-child(2),
.roi-table-main .table .table-td:nth-child(2) {
  width: 15% !important;
}
.stak-search-set {
  justify-content: end;
  margin: 20px 0px 10px;
}
.dash-token-box-set {
  /* max-width: 115px; */
  max-width: 190px;
  width: 100%;
  justify-content: left !important;
}
.page-table-main-income .table .table-th:nth-child(1),
.page-table-main-income .table .table-td:nth-child(1) {
  width: 20% !important;
}
.page-table-main-income .table .table-th:nth-child(2),
.page-table-main-income .table .table-td:nth-child(2) {
  width: 25% !important;
}
.page-table-main-income .table .table-th:nth-child(3),
.page-table-main-income .table .table-td:nth-child(3) {
  width: 20% !important;
}
.page-table-main-income .table .table-th:nth-child(4),
.page-table-main-income .table .table-td:nth-child(4) {
  width: 20% !important;
}
.page-table-main-income .table .table-th:nth-child(5),
.page-table-main-income .table .table-td:nth-child(5) {
  width: 20% !important;
}

.primary-border-btn-main .withdraw-amount-btn {
  font-size: 14px !important;
  line-height: 28px !important;
  cursor: none !important;
}
